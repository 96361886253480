import React from "react";
import "./Work.css";
import Projects from "./projects/Projects";
/* 
.work-section
.work-content
.work-text h1
.about-text h2
.about-text p 
.about-text p:first-of-type
 */

const Work = () => {
  return (
    <div className="work-section">
      <div className="work-content">
        <div className="work-content-container">
          <h1>More Work</h1>
          <p>
            Here are a few projects I've worked on. Want to see more?{" "}
            <a href="mailto:talalzeini@icloud.com" className="email-me-link">
              Email me
            </a>
            .
          </p>
        </div>
        <Projects />
      </div>
    </div>
  );
};

export default Work;
