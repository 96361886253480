import React, { useEffect, useState } from "react";
import SmileImage from "../../../../assets/images/landing/smile.webp";
import WinkImage from "../../../../assets/images/landing/wink.webp";
import "./LandingImage.css";

const LandingImage = () => {
  const [imagePath, setImagePath] = useState("");

  useEffect(() => {
    const winkInterval = setInterval(() => {
      setImagePath(WinkImage);
    }, 3000);

    const smileInterval = setInterval(() => {
      setImagePath(SmileImage);
    }, 1750);

    return () => {
      clearInterval(winkInterval);
      clearInterval(smileInterval);
    };
  }, []);

  return (
    <div>
        <img className="landing-profile-image" src={imagePath} alt="Bitmoji" />
    </div>
  );
};

export default LandingImage;
