import React from "react";
import cryptoTrackerImage from "../../../../assets/images/work/crypto-tracker.jpg";
import quizGeneratorImage from "../../../../assets/images/work/quiz-generator.jpg";
import sortingVisualizerImage from "../../../../assets/images/work/sorting.jpg";
import asteroidsGameImage from "../../../../assets/images/work/asteroids-game.jpg";
import hexagonsGameImage from "../../../../assets/images/work/hexagons-game.jpg";
import blocksGameImage from "../../../../assets/images/work/blocks-game.jpg";
import Project from "./project/Project";

const Projects = () => {
  return (
    <>
      <div className="row">
        <Project
          image={sortingVisualizerImage}
          title="Sorting Visualizer"
          repository="sorting-visualizer"
        />
        <Project
          image={cryptoTrackerImage}
          title="Crypto Tracker"
          repository="crypto-tracker"
        />
        <Project
          image={quizGeneratorImage}
          title="Quiz Generator"
          repository="quiz-generator"
        />
        <Project
          image={asteroidsGameImage}
          title="Asteroids Game"
          repository="asteroids-game"
        />
        <Project
          image={blocksGameImage}
          title="Blocks Game"
          repository="blocks-game"
        />
        <Project
          image={hexagonsGameImage}
          title="Hexagons Game"
          repository="hexagons-game"
        />
      </div>
    </>
  );
};

export default Projects;
