import React from "react";
import Footer from "../Layout/home/footer/Footer";
import Landing from "../Layout/home/landing/Landing";
import About from "../Layout/home/about/About";
import Work from "../Layout/home/work/Work";
import Apps from "../Layout/home/apps/Apps";

const Home = () => {
  return (
    <div>
      <Landing />
      <About />
      <Apps />
      <Work />
      <Footer />
    </div>
  );
};

export default Home;
