import React from "react";
import "./Project.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";

const Project = ({ image, title, repository, type }) => {
  const githubLink = "https://github.com/talalzeini/" + repository;
  let demoLink = "https://talalzeini.github.io/" + repository;

  if (repository.includes("game")) {
    demoLink = "https://talalzeini.itch.io/" + repository;
  }

  return (
    <>
      <div className="column">
        <a href={demoLink} target="_blank" rel="noreferrer">
          <div class="image-container">
            <img src={image} alt="dfhdfh" />
            <div class="overlay">
              <h2>{title}</h2>
              <div>
                <a href={githubLink} target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faGithub} />
                </a>
                <a href={demoLink}>
                  <FontAwesomeIcon
                    icon={faLink}
                    target="_blank"
                    rel="noreferrer"
                  />
                </a>
              </div>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

export default Project;
