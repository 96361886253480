import React from "react";
import "./MyApp.css";
import AppStoreButtonImage from "../../../../../assets/svg/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
const MyApp = ({ image, title, url }) => {
  return (
    <>
      <div className="app-column">
        <div class="app-image-container">
          <img src={image} alt="dfhdfh" />
        </div>
        <h2>{title}</h2>
        <a href={url}>
          <img src={AppStoreButtonImage} alt={"nkrw"}></img>
        </a>
      </div>
    </>
  );
};

export default MyApp;
