import { faMoon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "./Navbar.css";
import resumePDF from "../../../assets/files/talal-elzeini-resume.pdf";

const NavBar = () => {
  const [darkMode, setDarkMode] = useState(false);
  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    document.body.setAttribute("data-theme", darkMode ? "dark" : "light");
  }, [darkMode]);

  const toggleEmail = () => {
    window.location = "mailto:talalzeini@icloud.com";
  };

  const downloadResume = () => {
    const link = document.createElement("a");
    link.href = resumePDF;
    link.setAttribute("download", "talal-elzeini-resume.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <nav className="navbar">
      <div>
        <button onClick={toggleTheme} className="navbar-button moon-button">
          <FontAwesomeIcon icon={faMoon} />
        </button>
      </div>
      <div>
        <button
          onClick={downloadResume}
          className="navbar-button resume-button"
        >
          Resume
        </button>
        <button
          onClick={toggleEmail}
          className="navbar-button say-hello-button"
        >
          Say Hello
        </button>
      </div>
    </nav>
  );
};

export default NavBar;
