import React from "react";
import "./Landing.css";
import Navbar from "../navbar/Navbar";
import LandingImage from "./landing-image/LandingImage";
import Typewriter from "typewriter-effect";
const Landing = () => {
  return (
    <>
      <div className="landing-page">
        <Navbar />
        <div className="landing-page-content">
          <LandingImage />
          <h1>
            <Typewriter
              className="typewriter"
              options={{
                strings: [
                  "Software Engineer",
                  "Frontend Developer",
                  "iOS Engineer",
                  "Game Developer",
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </h1>

          <p>
            I design and code beautifully simple things, and I love what I do.
          </p>
          <div className="scroll-down-animation"></div>
        </div>
      </div>
    </>
  );
};

export default Landing;
