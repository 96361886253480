import React from "react";
import unitConverterAppImage from "../../../../assets/images/apps/unit-converter.webp";
import tipCalculatorAppImage from "../../../../assets/images/apps/tip-calculator.webp";
import MyApp from "./app/MyApp";

const MyApps = () => {
  return (
    <>
      <div className="row">
        <MyApp
          image={unitConverterAppImage}
          title="Unit Converter"
          url={
            "https://apps.apple.com/us/app/ultimate-unit-converter-pro/id6463096286"
          }
        />
        <MyApp
          image={tipCalculatorAppImage}
          title="Tip Calculator"
          url={
            "https://itunes.apple.com/us/app/ultimate-tip-calculator-pro/id6504262579"
          }
        />
      </div>
    </>
  );
};

export default MyApps;
