import React from "react";
import "./Apps.css";
import MyApps from "./my-apps/MyApps";

const Apps = () => {
  return (
    <div className="apps-section">
      <div className="apps-content">
        <div className="apps-text">
          <h1>My Apps</h1>
          <p>Here are my iOS Apps published on the App Store.</p>
          <MyApps />
        </div>
      </div>
    </div>
  );
};

export default Apps;
